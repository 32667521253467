import Vue from "vue";
import axios from "axios";

axios.defaults.baseURL =
  "https://recrutamento-51c0e-default-rtdb.europe-west1.firebasedatabase.app";

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios;
  },
});