<template>
  
    <v-img
      src="../assets/corte-desossa.jpg"
      width="100%"
      cover
      aspect-ratio="16/9"
      placeholder
      
    >
      <v-container>
        <h1>Formulário de Recrutamento (Corte e Desossa)</h1>
        <template>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="register"
          >
            <ol start="1">
              <h4 class="mt-10"><li>Informações Pessoais</li></h4>
              <v-radio-group v-model="gender" :rules="genderRules" required row>
                <template v-slot:label>
                  <div>Gênero*</div>
                </template>
                <v-radio color="success" label="Masculino" value="M"></v-radio>
                <v-radio color="success" label="Feminino" value="F"></v-radio>
              </v-radio-group>
              <div class="identation">
                <v-text-field
                  class="mr-2"
                  color="success"
                  v-model.trim="name"
                  :rules="nameRules"
                  label="Nome*"
                  required
                ></v-text-field>
                <v-text-field
                  class="mr-2"
                  color="success"
                  v-model.trim="surname"
                  :rules="surnameRules"
                  label="Apelido*"
                  required
                ></v-text-field>
                <v-menu
                  v-model="menu"
                  color="success"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthDate"
                      label="Data de Nascimento*"
                      color="success"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="birthDateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :active-picker.sync="activePicker"
                    color="success"
                    v-model="birthDate"
                    :max="minAge"
                    min="1962-01-01"
                    @click="ageMin"
                    @change="save"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="identation">
                <v-autocomplete
                  class="mr-2"
                  label="País de Nascimento *"
                  v-model="countryBirth"
                  color="success"
                  :items="countries"
                  required
                  :rules="required"
                ></v-autocomplete>

                <v-autocomplete
                  class="mr-2"
                  label="Nacionalidade *"
                  v-model="nationality"
                  color="success"
                  :items="countries"
                  required
                  :rules="required"
                ></v-autocomplete>
              </div>
              <h4><li>Contactos</li></h4>
              <div class="identation">
                <v-text-field
                  class="mr-2"
                  color="success"
                  v-model.trim="email"
                  :rules="emailRules"
                  label="E-mail"
                  prepend-inner-icon="mdi-email-outline"
                  required
                ></v-text-field>

                <vue-tel-input-vuetify
                  label="Telemóvel *"
                  color="success"
                  placeholder=""
                  :valid-characters-only="true"
                  :rules="phonelRules"
                  required
                  @input="onInput"
                />
              </div>
              <h4><li>Morada</li></h4>
              <div class="identation">
                <v-text-field
                  class="mr-2"
                  v-model.trim="morada"
                  color="success"
                  :rules="adressRules"
                  prepend-inner-icon="mdi-map-marker-outline"
                  label="Morada *"
                ></v-text-field>
                <v-autocomplete
                  class="mr-2"
                  label="País *"
                  color="success"
                  v-model="moradaPais"
                  :items="countries"
                  required
                  :rules="required"
                ></v-autocomplete>
              </div>
              <h4>
                <li>Quantos anos possui de experiência no ramo da carne?</li>
              </h4>
              <v-radio-group v-model="radioGroup">
                <v-radio
                  color="success"
                  v-for="n in xpState"
                  :key="n.state"
                  :label="`${n.state}`"
                  :value="n.state"
                ></v-radio>
              </v-radio-group>

              <h4><li>Data de início disponível</li></h4>
              <v-menu
                v-model="menu2"
                color="success"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="beginDate"
                    color="success"
                    prepend-icon="mdi-calendar"
                    label="Data de início disponível"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="success"
                  v-model="beginDate"
                  :min="today"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>

              <h4><li>Dados Adicionais</li></h4>
              <v-select
                v-model="bi"
                :items="documents"
                required
                :rules="biRules"
                label="Tipo de documentação*"
              ></v-select>
              <!-- <v-switch
              color="success"
              v-model="bi"
              label="Possuo Cartão Cidadão Português (BI)"
            ></v-switch> -->
              <v-switch
                color="success"
                v-model="car"
                label="Possuo Carro Próprio Disponível Para Viajar"
              ></v-switch>
            </ol>
            <v-checkbox
              color="success"
              v-model="checkbox"
              :rules="[
                (v) => !!v || 'Precisas aceitar os termos para continuar!',
              ]"
              required
            >
              <template v-slot:label>
                <div>
                  Declaro que li e aceito os
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="/recrutamento/privacy-policy"
                        @click.stop
                        v-on="on"
                      >
                        termos</a
                      >
                    </template>
                    Tratamento de Dados
                  </v-tooltip>
                  de tratamento de dados
                </div>
              </template></v-checkbox
            >
            <div class="mt-10">
              <v-btn
                v-if="!send"
                :disabled="!valid"
                color="success"
                class="mb-10 float-left"
                @click="validate"
                type="submit"
              >
                Enviar
              </v-btn>
            </div>
          </v-form>
        </template>
        <v-container v-if="send">
          <v-alert type="success">
            Dados enviados com sucesso! Obrigado por se cadastrar</v-alert
          >
        </v-container>
      </v-container>
    </v-img>
</template>

<script>
import DataCountries from "@/data/countries.json";
import axios from "axios";
export default {
  title: "Recrutamento Employer",
  data: () => ({
    gender: null,
    genderRules: [(v) => !!v || "Gênero é um campo obrigatório"],
    valid: true,
    name: "",
    nameRules: [(v) => !!v || "Nome é um campo obrigatório"],
    surname: "",
    surnameRules: [(v) => !!v || "Apelido é um campo obrigatório"],
    activePicker: null,
    birthDate: null,
    birthDateRules: [(v) => !!v || "Data de Nascimento é um campo obrigatório"],
    nationality: null,
    required: [(v) => !!v || "Campo obrigatório"],
    countryBirth: null,
    minAge: null,
    email: "",
    countries: DataCountries,
    emailRules: [
      (v) => !!v || "E-mail é um campo obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    phone: {
      number: "",
      valid: false,
      country: undefined,
    },
    phonelRules: [(v) => !!v || "Telemóvel é um campo obrigatório"],
    biRules: [(v) => !!v || "Tipo de documentação é obrigatório"],
    apiUrl: "https://geoapi.pt",
    rua: "",
    adressRules: [(v) => !!v || "Campo obrigatório!"],
    numero: "",
    localidade: null,
    complemento: null,
    zip: "",
    zipRules: [
      (v) => !!v || "Código Postal é Obrigatório",
      (v) => /^\d{4}(-\d{3})?$/.test(v) || "Código Postal Inválido",
    ],
    radioGroup: null,
    xpState: [
      { state: "+ de 10 Anos" },
      { state: "+ de 5 Anos" },
      { state: "+ de 3 Anos" },
      { state: "+ de 1 Ano" },
      { state: "Não possuo experiência" },
    ],
    documents: [
      "Cartão Cidadão Português (BI)",
      "Título de Residência",
      "Passaporte",
      "Cartão Cidadão Português (Direito de Igualdade)",
    ],
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    beginDate: null,
    date: false,
    menu: false,
    menu2: false,
    bi: null,
    car: false,
    checkbox: false,
    send: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onInput(formattedNumber, { number, valid, country }) {
      this.phone.number = number.international;
      this.phone.valid = valid;
      this.phone.country = country && country.name;
    },
    getData() {
      axios.get(`${this.apiUrl}/cp/${this.zip}?json=1`).then(
        (response) => (this.localidade = response.data.Localidade)
        // (this.distrito = response.data.Distrito)
      );
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    ageMin() {
      let d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      this.minAge = [year - 18, month, day - 1].join("-");
    },
    register() {
      if (this.$refs.form.validate()) {
        this.$http.post("recrutamento.json", {
          gender: this.gender,
          name: this.name,
          surname: this.surname,
          birthDate: this.birthDate,
          countryBirth: this.countryBirth,
          nationality: this.nationality,
          email: this.email,
          phone: this.phone.number,
          rua: this.rua,
          numero: this.numero,
          localidade: this.localidade,
          complemento: this.complemento,
          zip: this.zip,
          employState: this.radioGroup,
          beginDate: this.beginDate,
          bi: this.bi,
          car: this.car,
          registerDate: this.today,
          registerArea : "corte-desossa"
        });

        this.send = true;
        this.reload();
      }
    },
    reload() {
      setTimeout(function () {
        location.reload();
      }, 3000);
    },
  },
  watch: {
    zip() {
      this.zip.length == 8 ? this.getData() : (this.zip = "");
    },
    menu(val) {
      this.ageMin();
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    name() {
      this.name = this.name.charAt(0).toUpperCase() + this.name.slice(1);
    },
    surname() {
      this.surname =
        this.surname.charAt(0).toUpperCase() + this.surname.slice(1);
    },
    rua() {
      this.rua = this.rua.charAt(0).toUpperCase() + this.rua.slice(1);
    },
  },
};
</script>

<style scoped>
.identation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
h4 {
  text-align: left;
  margin-bottom: 2px;
  margin-top: 2px;
}
a:link {
  text-decoration: none;
  color: green;
}

a:visited {
  text-decoration: none;
  color: green;
}

a:hover {
  text-decoration: none;
  color: green;
}

a:active {
  text-decoration: none;
  color: green;
}
</style>
