<template>
  <div>
    <div v-for="(items, id) in dados" :key="id">
      <div v-if="items.car === true && items.nationality ==='Portugal' && items.contacted != true ">
        {{ items.name }} {{ items.surname }} - {{ items.beginDate }} - {{ items.bi }} - {{ items.nationality }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    dados: [],
  }),
  mounted() {
    this.getEmployes();
  },
  methods: {
    getEmployes() {
      this.$http.get("recrutamento.json").then((res) => {
        this.dados = res.data;
      });
    },
  },
};
</script>

<style></style>
