import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import titleMixin from '@/plugins/titleMixin'

Vue.mixin(titleMixin)

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
