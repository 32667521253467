<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8">
        <v-expansion-panels>
          <v-expansion-panel v-for="(employer, id) in employes" :key="id">
            <v-expansion-panel-header>
              {{ employer.name }} {{ employer.surname }},
              {{ getAge(employer.birthDate) }} anos
              <v-spacer></v-spacer>
              <div class="d-flex flex-row-reverse">
                <v-sheet class="mr-5" v-if="employer.gender == 'F'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"
                        >mdi-gender-female</v-icon
                      >
                    </template>
                    <span>Feminino</span>
                  </v-tooltip>
                </v-sheet>
                <v-sheet class="mr-5" v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-gender-male</v-icon>
                    </template>
                    <span>Masculino</span>
                  </v-tooltip>
                </v-sheet>

                <v-sheet class="mr-5" v-if="employer.car">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-car-back</v-icon>
                    </template>
                    <span>Possui Carro</span>
                  </v-tooltip>
                </v-sheet>

                <v-sheet class="mr-5" v-if="employer.bi === 'Cartão Cidadão Português' || employer.bi === true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"
                        >mdi-card-account-details-outline</v-icon
                      >
                    </template>
                    <span>Possui B.I</span>
                  </v-tooltip>
                </v-sheet>

                <v-sheet class="mr-5" v-if="employer.contacted">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" v-bind="attrs" v-on="on"
                        >mdi-check-all</v-icon
                      >
                    </template>
                    <span>Já Contactado</span>
                  </v-tooltip>
                </v-sheet>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn :href="`tel:${employer.phone}`" icon>
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ employer.phone }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-action>
                    <v-btn :href="`mailto:${employer.email}`" icon>
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ employer.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon>mdi-calendar-month</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Pode iniciar em {{ employer.beginDate }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon>mdi-flag</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Nascido em {{ employer.countryBirth }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon>mdi-map-marker</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ employer.rua }}, {{ employer.numero }},
                      {{ employer.zip }} -
                      {{ employer.localidade }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item-action-text class="d-flex flex-row-reverse">
                  <v-switch
                    v-if="!employer.contacted"
                    color="green"
                    label="Já Contactado"
                    v-model="mySwitch"
                    @click.native="toogleSwitch(id)"
                    :id="id"
                  />
                </v-list-item-action-text>
                <v-list-item-action-text class="d-flex flex-row-reverse">
                  Data de Registro
                  {{ employer.registerDate }}</v-list-item-action-text
                >
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  title: "Recrutamento Employer",
  data: () => ({
    employes: [],
    contacted: null,
    mySwitch: false,
    id: null,
    total: null,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),
  mounted() {
    this.getEmployes();
  },
  methods: {
    getEmployes() {
      this.$http.get("recrutamento.json").then((res) => {
        this.employes = res.data;
      });
    },
    getAge(dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    },
    formatDate(v) {
      let result1 = new Date().toLocaleDateString("en-GB");
      v = result1;
      return v;
    },

    toogleSwitch(id) {
      const updates = {
        contacted: true,
        contactedDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      };
      this.$http
        .patch(`recrutamento/${id}.json`, updates)
        .then(() => {
          this.refreshPage();
        })
        .catch((error) => {
          console.error("Erro ao atualizar nó:", error);
        });
    },
    recruted(id) {
      const updates = {
        recruted: true,
        recrutedDate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
      };
      this.$http
        .patch(`recrutamento/${id}.json`, updates)
        .then(() => {
          this.refreshPage();
        })
        .catch((error) => {
          console.error("Erro ao atualizar nó:", error);
        });
    },
    refreshPage() {
      location.reload();
    },
  },
};
</script>
<style></style>
