import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FormCarne from '../views/FormCarne.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
// import DataBase from '@/views/DataPeople.vue'
// import DataBase from '@/views/DataRecrutement.vue'
import RelsView from '@/views/RelsView.vue'
import DataBase from '@/views/DataView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/relatorios',
    name: 'home',
    component: RelsView
  },
  {
    path: '/recrutamento/privacy-policy',
    name: 'privacy',
    component: PrivacyPolicy
  },
  {
    path: '/recrutamento/data-base',
    name: 'data-base',
    component: DataBase
  },
  {
    path: '/recrutamento/corte-desossa',
    name: 'corte-desossa',
    component: FormCarne
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
